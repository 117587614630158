/* eslint-disable no-plusplus */
import { gql } from '@apollo/client';
import { modules } from '@config';

const productDetail = `
    id
    name
    sku
    ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
    stock_status
    url_key
    __typename
    attribute_set_id
    small_image{
      label
      url_original
    }
    image{
      url_original
    }
    review {
      rating_summary
      reviews_count
    }
    categories {
      id
      name
      url_path
      breadcrumbs {
        category_id
        category_url_path
        category_name
      }
    }
    special_from_date
    special_to_date

    `;
const priceRange = `
    price_range {
      minimum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        fixed_product_taxes {
          amount {
            currency
            value
          }
          label
        }
        regular_price {
          currency
          value
        }
      }
      maximum_price {
         discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        fixed_product_taxes {
          amount {
            currency
            value
          }
          label
        }
        regular_price {
          currency
          value
        }
      }
    }
    `;

const priceTiers = `
    price_tiers {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      quantity
    }
    `;

const vendorProductData = (vendorId) => `
  vendor_product_data(selectedStore: ${vendorId}) {
    vendor_id
    vendor_cost
    vendor_stock
    vendor_special_price
    flash_sale_price
    flash_sale_from
    flash_sale_to
    show_countdown
    preorder
    preorder_time
    preorder_time_config
  }
`;

/**
 * scema dynamic resolver url
 * @param url String
 * @returns grapql query
 */

export const getProduct = (url, vendorId) => {
    const query = gql`{
        products(
            search: "" ,filter: {
              url_key: {
                eq: "${url}"
              }
            }
          ) {
            items {
              weltpixel_labels {
              categoryLabel {
                css
                customer_group
                image
                page_position
                position
                priority
                text
                text_padding
                text_bg_color
                text_font_size
                text_font_color          
              }
              productLabel {
                css
                customer_group
                image
                page_position
                position
                priority
                text
                text_padding
                text_bg_color
                text_font_size
                text_font_color  
              }
            }
              ${vendorProductData(vendorId)}
              ${productDetail}
              ${priceRange}
              ${priceTiers}
              product_sold
              layanan_instalasi
              link_video
              sku_sensitive
              product_voucher_code
              description {
                html
              }
              ${modules.brands.enabled ? 'brand' : ''}
              short_description {
                html
              }
              more_info {
                label
                value
              }
              custom_weight
              product_dimension_height
              product_dimension_length
              product_dimension_width
              media_gallery {
                position,
                url_original
                label,
              }
              related_products {
               ${productDetail}
               ${priceRange}
               ${priceTiers}
              }
              meta_title
              meta_keyword
              meta_description
            }
            total_count
          }
    }`;
    return query;
};

export const customizableOptionsQuery = gql`
    query ProductCustomizableOptions($urlKey: String!) {
        products(filter: { url_key: { eq: $urlKey } }) {
            items {
                layanan_instalasi # must use an extra field, unless the inline fragments will not show
                ... on CustomizableProductInterface {
                    options {
                        option_id
                        ... on CustomizableFieldOption {
                            textField: value {
                                uid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getBundleProduct = (sku) => {
    const query = gql`{
    products(
      search: "" ,filter: {
        sku: {
          eq: "${sku}"
        }
      }
    ) {
      items {
        ... on BundleProduct {
          id
          name
          url_key
          items {
            position
            option_id
            title
            type
            required
            options {
              id
              is_default
              label
              quantity
              product {
                id
                name
                price_range {
                  minimum_price {
                    discount {
                      amount_off
                      percent_off
                    }
                    final_price {
                      currency
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
    return query;
};

export const getConfigurableProduct = (sku) => {
    const query = gql`{
      products(
        search: "" ,filter: {
          sku: {
            eq: "${sku}"
          }
        }
      ) {
        items {
          ... on ConfigurableProduct {
            configurable_options {
              id
              attribute_id
              label
              position
              use_default
              attribute_code
              values {
                value_index
                label
                swatch_data {
                  value
                  ... on ImageSwatchData {
                    thumbnail
                    value
                  }
                  ... on ColorSwatchData {
                    value
                  }
                  ... on TextSwatchData {
                    value
                  }
                }
              }
              product_id
            }
            variants {
              product {
                ${productDetail}
                ${priceRange}
                ${priceTiers}
                media_gallery {
                  position,
                  label,
                  url_original
                }
              }
              attributes {
                label
                code
                value_index
              }
            }
          }
        }
      }
    }`;
    return query;
};

export const createEmptyCartGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const addSimpleProductsToCart = gql`
mutation addSimpleProductsToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
) {
    addSimpleProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        data: {
          quantity: $qty,
          sku: $sku
        }
      }
    }) {
      cart {
        id
        total_quantity
      }
    }
  }
`;

export const addProductsToCartVendor = gql`
mutation addProductsToCartVendor(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    $vendor_id: Int!,
    $is_pickup: Int!,
    $enteredOptions: [EnteredOptionInput],
) {
  addProductsToCartVendor(
      cartId: $cartId,
      cartItems: [
        {
          quantity: $qty
          sku: $sku
          udropship_vendor: $vendor_id
          is_pickup: $is_pickup
          entered_options: $enteredOptions
        }
      ]
    ) {
      cart {
        id
        total_quantity
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const addVirtualProductToCart = gql`
mutation addVirtualProductToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
) {
    addVirtualProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        data: {
          quantity: $qty,
          sku: $sku
        }
      }
    }) {
      cart {
        id
        total_quantity
      }
    }
  }
`;

export const addConfigProductsToCart = gql`
mutation (
  $cartId: String!,
  $qty: Float!,
  $sku: String!,
  $parentSku: String!,
) {
  addConfigurableProductsToCart(
    input: {
      cart_id: $cartId,
      cart_items: {
        data: {
          quantity : $qty,
          sku: $sku
        }
        parent_sku: $parentSku
      }
    }
  ) {
    cart {
      id
      total_quantity
    }
  }
}
`;

export const addBundleProductsToCart = gql`
  mutation (
    $cartId: String!,
    $cartItems: [BundleProductCartItemInput]!
  ) {
        addBundleProductsToCart(
          input: {
            cart_id: $cartId
            cart_items: $cartItems
          }
        ) {
          cart {
            id
            total_quantity
          }
        }
      }
`;

export const addReview = gql`
    mutation createReview($nickname: String!, $rating: Int!, $title: String!, $detail: String!, $pkValue: Int!) {
        addProductReview(
            input: {
                entity_pk_value: $pkValue
                title: $title
                detail: $detail
                nickname: $nickname
                ratings: { rating_name: "Rating", value: $rating }
            }
        ) {
            message
        }
    }
`;

export const getReview = () => {
    const query = gql`
        query getReview($sku: String!, $pageSize: Int, $currentPage: Int) {
            getProductReviews(sku: $sku, pageSize: $pageSize, currentPage: $currentPage) {
                items {
                    id
                    nickname
                    ratings {
                        rating_name
                        value
                    }
                    entity_pk_value
                    review_entity
                    review_type
                    review_status
                    title
                    detail
                    created_at
                }
                message
                totalCount
            }
        }
    `;
    return query;
};

export const addWishlist = gql`
    mutation addWishlist($productId: Int!) {
        addProductToWishlist(productId: $productId) {
            info
        }
    }
`;

export const createCartIdGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const getAvailableSource = gql`
    query getAvailableSource($productId: Int) {
        getAvailableSource(product_id: $productId) {
            default_vendor {
                stock_qty
                street
                vendor_id
                vendor_name
            }
            list {
                stock_qty
                street
                vendor_id
                vendor_name
                preorder
            }
        }
    }
`;

export const getCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
          id
          items_vendor {
            vendor_id
            vendor_name
            items {
              id
              product {
                id
                name
                sku
              }
              pickup_item_store_info {
                is_pickup
              }
            }
        }
      }
    }
`;
export const getXenditKredivoPaylaterPlans = gql`
    query getXenditKredivoPaylaterPlans(
      $xenditCustomerId: String!
      $sku: String!
      $productName: String!
      $productPrice: Int!
      $fullUrl: String!
      $categoryProduct: String!
    ) {
      xenditKredivoPaylaterPlans(
        xendit_customer_id: $xenditCustomerId
        sku: $sku
        product_name: $productName
        product_price: $productPrice
        full_url: $fullUrl
        category_product: $categoryProduct
      ) {
        sku
        product_name
        product_price
        options {
          downpayment_amount
          installment_amount
          interest_rate
          total_amount
          interval
          interval_count
          total_recurrence
          description
        }
      }
    }
`;

export const getindodanaPaymentCalculation = gql`
  query getindodanaPaymentCalculation($sku: String!, $productName: String!, $productPrice: Int!) {
    indodanaPaymentCalculation(
      sku: $sku
      product_name: $productName
      product_price: $productPrice
    ) {
      sku
      product_name
      product_price
      options {
        amount
        installmentAmount
        monthlyInstallment
        tenure
        paymentType
        totalInterest
        rate
        id
        merchantDiscountRate
      }
    }
  }
`;

export const getInstalationGuideSchema = gql`
  query getInstallationGuide($sku: String) {
    getAttrPim(sku: $sku) {
      installation_guide
      sni_no
      k3l_no
    }
  }
`;

export const setLastViewedSchema = gql`
  mutation setLastViewedProduct($product_id: Int!) {
    setLastViewedProduct(input: { product_id: $product_id }) {
      status
      __typename
    }
  }
`;

export default {
    createEmptyCartGuest,
    addSimpleProductsToCart,
    addProductsToCartVendor,
    getAvailableSource,
};
